<template>
  <div class="card" :style="{ backgroundColor: project.color }" @click="changeProject">
    <div :style="{ 'background-color': `rgba(${hexToRgb(project.color)}, 0.95)` }">
      <div>
        <h1>{{ project.title }}</h1>
        <h2>{{ project.subtitle[$store.state.language].replace("<br />", "") }}</h2>
      </div>
      <div>
        <div>
          <h4>
            <span v-for="(tag, index) in project.tags" :key="tag">
              {{ tag }}
              <span v-if="index < project.tags.length - 1"> | </span>
            </span>
          </h4>
        </div>
        <icon icon="arrow-right" />
      </div>
    </div>
    <Image :src="project.id + '.jpg'" />
  </div>
</template>

<script>
  import Image from '@/components/Image.vue';

  export default {
    props: {
      project: Object
    },

    components: {
      Image
    },

    methods: {
      async changeProject() {
        await this.$router.replace({ name: 'Content', params: { content: this.project.id } })
      },

      hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
          : "0, 0, 0";
      }
    },
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  .card {
    overflow: hidden;
    height: 50vh;
    max-height: 600px;

    >img {
      position: absolute;
      height: 105%;
      width: 105%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      background-color: white;
      object-fit: cover;
    }

    >div {
      width: 100%;
      height: 100%;
      opacity: 0;
      padding: 1.25em;
      //padding-top: 1.5em;
      z-index: 1;
      justify-content: space-between;

      h1 {
        font-size: 5em;
        text-shadow: rgba(v.$background-color, 0.4) 0 0.1em;
        letter-spacing: -0.025em;
      }

      h4, span {
        font-weight: 600;
        opacity: 0.75;
      }

      >div:first-child {
        gap: 1em;
        flex-grow: 1;
        justify-content: center;

        >h1 {
          text-align: start;
        }

        >h2 {
          text-align: start !important;
          padding: 0 0.15em !important;
          line-height: 90%;
        }
      }

      // hover overlay fpr cards
      >div:last-child {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-items: end;
        gap: 1em;

        >div {
          gap: 0.5em;
          flex-grow: 1;
          flex-direction: row;
          flex-wrap: wrap;
        }

        svg {
          height: 1.75em;
          color: v.$text-color;
        }
      }

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
</style>