<template>
  <div>
    <Header :scroll="scroll">
      <div>
        <h2 v-if="$store.state.language === 'EN'">Hi, I am</h2>
        <h2 v-else-if="$store.state.language === 'DE'">Hi, Ich bin</h2>
        <Title text="Mikias!" />
        <p v-if="$store.state.language === 'EN'">In this portfolio you can explore a selection of my previous work as a Digital Product
          Designer & Developer.</p>
        <p v-else-if="$store.state.language === 'DE'">In diesem Portfolio kannst Du eine Auswahl meiner bisherigen Arbeit als Digital
          Product Designer & Developer erkunden.</p>
      </div>
      <div v-if="$store.state.language === 'DE'">
        <Button class="primary" style="pointer-events: auto;" @click="$router.push('/about')">Mehr Über Mich</Button>
        <div>
          <icon icon="arrow-down" />
          <h3>... oder scrolle, um meine Projekte zu sehen!</h3>
        </div>
      </div>
      <div v-else>
        <Button class="primary" style="pointer-events: auto;" @click="$router.push('/about')">More About Me</Button>
        <div>
          <icon icon="arrow-down" />
          <h3>... or scroll to see my recent projects!</h3>
        </div>
      </div>
    </Header>

    <div id="content">
      <div>
        <GalleryCard v-for="project in projects" :key="project" :project="project" />
      </div>

      <Email />
      <Footer />
    </div>
  </div>
</template>

<script>
  import { projects } from "../data.js"
  import GalleryCard from "@/components/GalleryCard.vue"
  import Title from "@/components/Title.vue"
  import Button from "@/components/Button.vue"
  import Footer from "@/components/Footer.vue"
  import Email from "@/components/Email.vue"
  import Header from "@/components/Header.vue"

  export default {
    components: {
      GalleryCard,
      Title,
      Button,
      Footer,
      Email,
      Header
    },

    data() {
      return {
        projects: projects,
      }
    },

    props: {
      scroll: Number
    }
  }
</script>

<style lang="scss" scoped>
  @use "variables.module" as v;

  .spacer {
    margin: 2*v.$viewport-padding 0;
  }

  #content {
    background-color: v.$background-color;
    gap: calc(v.$viewport-padding*3.5);
    //padding-bottom: calc(v.$viewport-padding*3.5);

    >div:first-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 0.5em;
      grid-row-gap: 0.5em;

      >div:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
      }

      >div:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        border-radius: 0 0.5em 0.5em 0;
      }

      >div:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
        border-radius: 0.5em 0 0 0.5em;
      }

      >div:nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
      }
    }
  }

  #title {
    div:first-child {
      flex-grow: 1;
      justify-content: center;
      gap: 1.5em;

      >h2 {
        margin: -0.5em 0.2em;
        align-self: flex-start;
        animation: delay 3s;
      }

      >p {
        max-width: 20em;
        align-self: flex-end;
        animation: delay 3s;
      }
    }

    div:last-child {
      gap: 1.25em;

      button {
        width: 100%;
      }

      >div {
        flex-direction: row;
        align-items: center;
        gap: 0.25em;

        svg {
          color: v.$text-color;
        }
      }
    }
  }

  @keyframes delay {

    0%,
    75% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
</style>